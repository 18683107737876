import React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import TaxFlowMoneyItem from '@app/src/Components/TaxFlow/Question/TaxFlowMoneyItem';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import TaxValidationSelect from '@app/src/Components/TaxValidation/TaxValidationSelect';
import { replaceStrings } from '@app/src/taxflow/common';
import '@app/src/Components/TaxFlow/Form/TaxFlowFormTwelveAItem.scss';

class TaxFlowFormTwelveAItem extends React.Component {
  componentDidMount() {
    const { question, answer } = this.props;

    if (_.isNil(_.get(answer, 'value'))) {
      const item = question.question_meta.options.find((x) => x.default === 1);
      if (item) {
        this.props.onChange({ slug: question.slug, value: item.value });
      }
    }
  }

  handlePartOneChange = (value) => {
    this.props.onChange({
      slug: this.props.question.slug,
      value: { ..._.get(this.props.answer, 'value'), partOne: value },
      endpoint_attr: this.props.question.endpoint_attr
    });
  };

  handlePartTwoChange = (value) => {
    value = value ? Number(value) : null;
    this.props.onChange({
      slug: this.props.question.slug,
      value: { ..._.get(this.props.answer, 'value'), partTwo: value }
    });
  };

  handleFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus({ endpoint_attr: this.props.question.endpoint_attr });
    }
  };

  handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur({ question: this.props.question, answer: this.props.answer });
    }
  };

  render() {
    const { question, answer } = this.props;
    const questionMeta = question.question_meta;

    const answerPartOne = { value: _.get(answer, ['value', 'partOne']) };
    const answerPartTwo = { value: _.get(answer, ['value', 'partTwo']) };

    return (
      <div className='form-group'>
        <TaxValidationLabel question={question} answer={answer} title={replaceStrings(question.title)} />
        <Box
          sx={{
            display: 'flex',
            gap: '8px'
          }}
        >
          <TaxValidationSelect
            question={question}
            className='taxflow-form-twelve-a-item-part-one browser-default'
            name={question.slug}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={(e) => this.handlePartOneChange(e.target.value)}
            value={_.get(answerPartOne, 'value', '')}
            short
          >
            {questionMeta.options.map((item) => (
              <option value={item.value} key={item.value}>
                {item.text}
              </option>
            ))}
          </TaxValidationSelect>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end'
            }}
          >
            <TaxFlowMoneyItem
              {...this.props}
              isChildQuestion
              currentQuestion={question}
              currentAnswer={answerPartTwo}
              inputMode='decimal'
              decimalScale={2}
              onChange={this.handlePartTwoChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            />
          </Box>
        </Box>
      </div>
    );
  }
}

export default TaxFlowFormTwelveAItem;
